<template>
  <Section>

    <Container>
      <FadeIn class="mx-auto max-w-2xl lg:text-center mb-12" v-if="content.title">
        <h2 class="text-section-title">{{ content.title }}</h2>
        <p class="text-subtitle">{{ content.subtitle }}</p>
      </FadeIn>

      
      <FadeIn class="grid gap-4 md:gap-6 lg:gap-8 grid-cols-1 lg:grid-cols-3 max-w-[1080px] mx-auto">
        <div 
        v-for="(stat, index) in content.items"
        class="flow-root rounded-xl p-6 h-full bg-[#edf2ed] flex flex-col justify-between lg:aspect-square flex-1"
        >
          <div 
          class="font-bold tracking-tight text-gray-900"
          :class="statClasses">
            {{ stat.title }}
          </div>
          <div class="mt-6 text-base text-gray-900">
            {{ stat.subtitle }}
            <a v-if="stat.url" :href="stat.url" target="_blank" class="ml-1 relative -top-[1px]" aria-label="Click here to view the source">
              <Icon name="iconoir:link" class="h-4 w-4" />
            </a>
          </div>
        </div>
      </FadeIn>
    
    </Container>

  </Section>
</template>

<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true
  }
})

const statClasses = computed(() => {
  if (!props.content?.items) return
  let size = 0
  props.content?.items.forEach(item => {
    if (item.title.length && item.title.length >  size) size = item.title.length
  })
  if (size > 15) return 'text-2xl md:text-3xl lg:text-3xl'
  if (size > 10) return 'text-2xl md:text-3xl lg:text-4xl'
  return 'text-3xl md:text-4xl lg:text-5xl'
})

</script>